import React, { useRef, useState } from "react"
import { useFrame, invalidate } from "@react-three/fiber"
//import { useGLTF } from "@react-three/drei"
import { DoubleSide } from 'three';
import Store from '../Store/Store'

export default function Icosahedrons( props ) {

  let refs = useRef({});
  refs.current = Array( 5 ).fill().map( (_, i) => refs.current[ i ] )

  const [ ticks, setTicks ] = useState( 0 )
  const [ rotations, setRotations ] = useState( null )
  const [ positions, setPositions ] = useState( null )
  if ( !rotations || !positions ) { 
    let initialRotations = [], initialPositions = []
    for ( let i = -1; i <= 1; i = i + 0.5 ) {
      initialRotations.push( [ 0.785, 0, 0.785 ] )
      initialPositions.push( [ i , 0, 0 ] )
    }
    setRotations( initialRotations )
    setPositions( initialPositions )
  }

  useFrame( ( state ) => {
    if ( refs.current[ 0 ] === null ) return

    if ( window.pageYOffset !== 0 ) {
      invalidate()
      return  
    }

    setTicks( ticks + 1 )

    const speed = props.speed || 0.0003
    const speedDifference = props.speedDifference || 0.00003

    let nextRotations = []
    rotations.forEach( ( r, i ) => { 
      nextRotations.push( [ 
        r[ 0 ] + speed + ( ( 4 - i ) * speedDifference ), 
        r[ 1 ] + speed + ( i * speedDifference ), 
        r[ 2 ] + speed
      ] )
    } )
    setRotations( nextRotations )

    if ( props.horizontal ) {
      let nextPositions = []
      positions.forEach( ( r, i ) => { 
        nextPositions.push( [ ( i / 2 ) - 1 + ( Math.sin( ( ( ticks / 6 ) / Math.PI * ( ( i - 2 ) / 3 ) ) / 20 ) / 2 ), r[ 1 ], r[ 2 ] ] )
      } )
      setPositions( nextPositions )
    }

  })

  const MeshInstance = props => (
    <mesh ref={ props.refTo } position={ props.position } rotation={ props.rotation } castShadow receiveShadow >
      <icosahedronGeometry attach="geometry" args={[ props.size || 0.1475 , props.roundness || 0 ]} />
      <meshStandardMaterial 
        color={ props.color } 
        attach="material" 
        side={ DoubleSide } 
        roughness={ 0.15 } 
        metalness={ 0.8 } 
        transparent={ true } 
        opacity={ 0.75 }
      />
    </mesh>
  )

  if ( positions === null || rotations === null ) return null

  return (
    <group 
      { ...props } 
      dispose={ null }
    >

      { refs.current.map( ( a, i ) => (
        <MeshInstance 
          refTo={ refs.current[ i ] } 
          position={ positions[ i ] } 
          rotation={ rotations[ i ] } 
          color={ props.colors[ i ] } 
          roundness={ props.roundness } 
          size={ props.size }
        />
      ) ) }

    </group>
  )
}
