import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'; 
import parseHtml from '../parseHtml/parseHtml';
import styled from 'styled-components'
import Store from '../Store/Store'

const FlexDiv = styled.div`
  display:flex; 
  flex-direction:column;
  align-items:center;
  justify-content:center;
  background:#000;
  color:#78909c;
  padding:10px 0;
  @media only screen and ( min-width: 961px) {
    flex-direction:row;
  }
`;

const PipsBox = props => (
  <div style={{ padding:'20px', textAlign:'center' }} >
    <div style={{ fontSize:'34px' }} >{ props.figure }</div>
    <div style={{ fontSize:'9px' }} >{ props.title }</div>
  </div>
)

const Figures = props => {
  const f = JSON.parse( props.structure.column )
  return (
    <div className="products-wrapper" style={{ 
      backgroundColor:props.structure.backgroundColor || '#fff', 
      color:props.structure.color || '#000', 
      padding:'0', 
      boxSizing:'border-box', 
    }} >
      <div className="products" style={{ maxWidth:'900px', paddingBottom:0 }}  >
      <FlexDiv>
        { f.map( ( a, i ) => {
          return (
            <PipsBox
              key={ a[ 0 ].value + '-' + i } 
              figure={ a[ 0 ].value } 
              title={ a[ 1 ].value } 
            />
          )  
        } ) }
      </FlexDiv>
      </div>
    </div>
  )
}

export default Figures
