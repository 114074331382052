import React from 'react';

const BlogCard = props => (
	<table className="card-hover" style={{ maxWidth:'240px' }} >
		<tbody>
			<tr><td colSpan="2" style={{ height:'150px', maxHeight:'150px', padding:'0', margin:'0', boxSizing:'border-box' }} >
				<img 
					src={ props.row.img } 
					draggable="false" 
					alt="" 
					style={{ objectFit:'cover', width:'100%', height:'150px', boxSizing:'border-box',  }} 
				/>
			</td></tr>
			<tr><td colSpan="2" style={{ padding:'4px 10px 10px 10px', verticalAlign:'top', boxSizing:'border-box' }} >
		    	<h3 style={{ padding:'0', margin:'0', fontSize:'14px', lineHeight:'18px', height:'18px', overflow:'hidden', boxSizing:'border-box' }} >{ props.row.display_date }</h3>
			</td></tr>
			<tr><td colSpan="2" style={{ padding:'4px 10px 10px 10px', verticalAlign:'top', boxSizing:'border-box' }} >
		    	<h3 style={{ padding:'0', margin:'0', fontSize:'18px', lineHeight:'24px', height:'72px', overflow:'hidden', boxSizing:'border-box' }} >{ props.row.title }</h3>
			</td></tr>
		</tbody>
	</table>
)

export default BlogCard
