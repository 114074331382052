import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'; 
import parseHtml from '../parseHtml/parseHtml';
import styled from 'styled-components'
import Store from '../Store/Store'
import ActionButton from '../ActionButton/ActionButton';

const FlexDiv = styled.div`
  display:flex; 
  flex-direction:column;
  align-items:center;
  justify-content:center;
  background:#000;
  color:#78909c;
  padding:10px 0;
  @media only screen and ( min-width: 961px) {
    flex-direction:row;
  }
`;

const PipsBox = props => (
  <div style={{ padding:'20px', textAlign:'center' }} >
    <div style={{ fontSize:'34px' }} >{ props.figure }</div>
    <div style={{ fontSize:'9px' }} >{ props.title }</div>
  </div>
)


const BidAskBox = props => (
  <div style={{ padding:'0 10px 0 0' }} >
    <div style={{ fontSize:'12px', fontWeight:'700', color:'#546e7a' }} >{ props.title }</div>
    <div style={{ display:'flex', color:'#00c853', alignItems:'flex-end' }} >
      <div style={{ fontSize:'17px', transform:'translateY(5px)' }} >{ props.figure1 }</div>
      <div style={{ fontSize:'32px', padding:'0 5px' }} >{ props.figure2 }</div>
      <div style={{ fontSize:'17px', transform:'translateY(-5px)' }} >{ props.figure3 }</div>
    </div>
  </div>
)

const CurrencyBox = props => (
  <div style={{ padding:'20px', textAlign:'left' }} >
    <div style={{ display:'flex', alignItems:'flex-end', justifyContent:'left' }} >
      <div style={{ fontSize:'14px', fontWeight:'700' }} >{ props.title }</div>
      <div style={{ fontSize:'12px', fontWeight:'700', color:'#00c853', justifySelf:'end', padding:'0 5px', transform:'translateY(7px)' }} ><span className="material-icons">trending_up</span></div>
      <div style={{ fontSize:'12px', fontWeight:'300' }} >Spread:</div>
      <div style={{ fontSize:'12px', fontWeight:'700', color:'white', paddingLeft:'5px' }} >{ props.spread }</div>
    </div>
    <div style={{ display:'flex', alignItems:'center', justifyContent:'center', padding:'0' }} >
      <BidAskBox title="Bid" figure1={ props.bid1 } figure2={ props.bid2 } figure3={ props.bid3 } />
      <BidAskBox title="Ask" figure1={ props.ask1 } figure2={ props.ask2 } figure3={ props.ask3 } />
    </div>
  </div>
)

const CallToAction = props => (
  <FlexDiv>
    <ActionButton style={{ background:'#00c853', margin:'10px', color:'black' }} title="START TRADING" />
    <ActionButton style={{ background:'#455a64', margin:'10px' }} title="TRY A FREE DEMO" />
  </FlexDiv>
)


const BidsAndAsks = props => {
  const f = JSON.parse( props.structure.column )
  return (
    <div className="products-wrapper" style={{ 
      backgroundColor:props.structure.backgroundColor || '#fff', 
      color:props.structure.color || '#000', 
      padding:'0', 
      boxSizing:'border-box', 
    }} >
		<div className="products" style={{ maxWidth:'900px', paddingBottom:0 }}  >
		  <FlexDiv>
	        { f.map( ( a, i ) => {
	          return (
			    <CurrencyBox
			    	key={ a[ 0 ].value + '-' + i } 
			    	title={ a[ 0 ].value } 
			    	bid1={ a[ 1 ].value } 
			    	bid2={ a[ 2 ].value } 
			    	bid3={ a[ 3 ].value }
			    	ask1={ a[ 4 ].value }
			    	ask2={ a[ 5 ].value }
			    	ask3={ a[ 6 ].value }
			    	spread={ a[ 7 ].value }
			    />
	          )  
	        } ) }
		  </FlexDiv>
          <CallToAction/>
		</div>
    </div>
  )
}

export default BidsAndAsks
