import React, { Fragment } from 'react';
import BlogsList from '../Blogs/BlogsList';

const Blogs = props => (
  <Fragment>
    <div id="blog" className="products-wrapper" style={{ backgroundColor:props.structure.backgroundColor, color:props.structure.color, padding:'40px 0 20px 0' }} >
      <div className="products" style={{ maxWidth:'900px' }} >
        <h1 style={{ padding:'0px 30px 0 30px', margin:'0', lineHeight:'48px', boxSizing:'border-box', userSelect:'none' }} >Blog</h1>
        <BlogsList
          limit="12"
          backgroundColor={ props.structure.backgroundColor }
          color={ props.structure.color }
          backFromPageTo="back" 
        />
      </div>
      </div>
  </Fragment>
)

export default Blogs
