import React, { Fragment } from 'react';
import Request from '../Request/Request';
import parseHtml from '../parseHtml/parseHtml';
import ActionButton from '../ActionButton/ActionButton';
import Store from '../Store/Store'

import '../css/index.css';

class Subscribe extends React.Component {

  constructor( props ) {
    super( props );
    this.state = { 
      tmp:null,
      status:0,
      name:'',
      email:'',
      phone:'',
      email:'',
    }
    //  this.hideGallery = this.hideGallery.bind(this);
  }

  updateInputValue( e, stateObject ) {
    this.setState( { [ stateObject ]:e.target.value } )
    //console.log( 'this.state', this.state )
  }

  send() {
    if ( this.state.name.length < 4 || this.state.email.length < 5 || this.state.phone.length < 5 ) return
    Request.post( 'posting-list-insert', { name:this.state.name, email:this.state.email, phone:this.state.phone, fund:this.props.structure.fund }, () => {
      this.setState( { status:2 } )      
    } )
  }

  render() {
    let structure = this.props.structure || {}
    let lang = Store.get( 'lang' )
    let title = lang === 'fi' ? this.props.structure.titleFi : ( this.props.page.title || this.props.title )
    return ( 
     <Fragment>
        <div id="subscribe" className="products-wrapper" 
             style={{ 
                backgroundColor:structure.backgroundColor || '#fff', 
                color:structure.color || '#000',
                paddingBottom:'40px'
        }} >
          <div className="products" style={{ maxWidth:'900px', textAlign:'left', padding:'5px 30px 0 30px' }} >
            {/*
            */}
            { this.state.status === 0 &&
              <Fragment>
                <div onClick={ () => { this.setState( { status:1 } ) } } style={{ display:'inline-block', padding:'10px 10px 12px 0', verticalAlign:'bottom' }} >
                  <ActionButton title={ title } />
                </div>
              </Fragment>
            }

            { this.state.status === 1 &&
              <Fragment>
                <h3 style={{ lineHeight:'48px', boxSizing:'border-box', margin:'10px 0', userSelect:'none' }} >{ title }</h3>
                { parseHtml( ( lang === 'fi' ? this.props.structure.columnFi : this.props.structure.column ) || '' ) }

                <div style={{ display:'block', padding:'10px 15px 10px 0' }} >
                  { lang === 'fi' ? "Nimi *" :  "Name *" }<br/>
                  <input 
                    name="name" type="text" value={ this.state.name } onChange={ e => this.updateInputValue( e, 'name' ) } 
                    style={{ padding:'8px', fontFamily:'Montserrat', width:'205px', margin:'8px 0 0 0' }} 
                  />
                </div>

                <div style={{ display:'block', padding:'10px 15px 10px 0' }} >
                  { lang === 'fi' ? "Sähköposti *" :  "Email *" }<br/>
                  <input 
                    name="email" type="email" value={ this.state.email } onChange={ e => this.updateInputValue( e, 'email' ) } 
                    style={{ display:'block', padding:'8px', fontFamily:'Montserrat', width:'205px', margin:'8px 0 0 0' }} 
                  />
                </div>

                <div style={{ display:'block', padding:'10px 15px 10px 0' }} >
                  { lang === 'fi' ? "Puhelin *" :  "Phone *" }<br/>
                  <input 
                    name="phone" type="tel" value={ this.state.phone } onChange={ e => this.updateInputValue( e, 'phone' ) } 
                    style={{ display:'block', padding:'8px', fontFamily:'Montserrat', width:'205px', margin:'8px 0 0 0' }} 
                  />
                </div>

                <div onClick={ () => { this.send() } } style={{ display:'inline-block', padding:'10px 10px 12px 0', verticalAlign:'bottom' }} >
                  <br/>
                  <ActionButton title={ title } />
                </div>

                <div style={{ display:'block', padding:'25px 15px 10px 0', fontSize:'13px' }} >
                  { lang === 'fi' ? "Tähdellä (*) merkityt kentät ovat pakollisia" :  "Fields marked with an asterisk (*) are mandatory" }<br/>
                </div>

              </Fragment>
            }

            { this.state.status === 2 &&
              <Fragment>
                <h3 style={{ lineHeight:'48px', boxSizing:'border-box', margin:'10px 0', userSelect:'none' }} >{ title }</h3>
                <p>
                  { lang === 'fi' ? "Kiitos!" :  "Thank you!" }
                </p>
                <p>
                  { lang === 'fi' ? "Otamme sinuun pian yhteyttä sähköpostitse." :  "We will contact you soon by email." }
                </p>
              </Fragment>
            }

          </div>
        </div>
      </Fragment>
    ) 
  }

}

export default Subscribe

