import axios from 'axios';
import Store from '../Store/Store';

var Request = function() {

	const apiRoot = process.env.REACT_APP_API

	let handleError = ( error, onError ) => {
		if ( typeof onError !== 'undefined' ) {
			onError( error )
		} else {
			console.error( error )
		}
	} 

	return {
		get:function( api, onSuccess, onError, endpoint ){
			const user = Store.get( 'user' )
			axios( {
		        method: 'GET',
		        url: ( endpoint || apiRoot ) + api,
		        headers: {
		            "Content-Type": "application/json",
		    		//"X-Token":user.token,
		        },
			} )
			.then( onSuccess )
			.catch( error => { handleError( error, onError ) } )
		},
		getWithToken:function( api, token, onSuccess, onError, endpoint ){
			const user = Store.get( 'user' )
			axios( {
		        method: 'GET',
		        url: ( endpoint || apiRoot ) + api,
		        headers: {
		            "Content-Type": "application/json",
		    		"X-Token":token,
		        },
			} )
			.then( onSuccess )
			.catch( error => { handleError( error, onError ) } )
		},
		xhr:function( method, api, data, onSuccess, onError ){
			axios( {
		        method: method,
		        url: apiRoot + api,
		        data: data ? JSON.stringify( data ) : {}, 
		        headers: {
		            "Content-Type": "application/json",
		        },
	    	} )
			.then( onSuccess )
			.catch( error => { handleError( error, onError ) } )
		},
		post:function( api, data, onSuccess, onError ){
			axios( {
		        method: 'POST',
		        url: apiRoot + api,
		        data: data ? JSON.stringify( data ) : {}, 
		        headers: {
		            "Content-Type": "application/json",
		        },
	    	} )
			.then( onSuccess )
			.catch( error => { handleError( error, onError ) } )
		},
		put:function( user, api, data, onSuccess, onError ){
			axios( {
		        method: 'POST',
		        url: apiRoot + api,
		        data: data ? JSON.stringify( data ) : {}, 
		        headers: {
		            "Content-Type": "application/json",
		    		"X-Token":user.token,
		        },
	    	} )
			.then( onSuccess )
			.catch( error => { handleError( error, onError ) } )
		},
		delete:function( api, data, onSuccess, onError ){
			axios( {
		        method: 'DELETE',
		        url: apiRoot + api,
		        data: data ? JSON.stringify( data ) : {}, 
		        headers: {
		            "Content-Type": "application/json",
		        },
	    	} )
			.then( onSuccess )
			.catch( error => { handleError( error, onError ) } )
		},
	}

}();

export default Request

