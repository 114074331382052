import React, { Fragment } from 'react';
import Request from '../Request/Request';
import ActionButton from '../ActionButton/ActionButton';

import '../css/index.css';

class Register extends React.Component {

  constructor( props ) {
    super( props );
    this.state = { 
      tmp:null,
      done:false,
      name:'',
      email:'',
    }
    //  this.hideGallery = this.hideGallery.bind(this);
  }

  updateInputValue( e, stateObject ) {
    this.setState( { [ stateObject ]:e.target.value } )
    //console.log( 'this.state', this.state )
  }

  send() {
    if ( this.state.name.length < 4 || this.state.email.length < 5 ) return
    Request.post( 'posting-list-insert', { name:this.state.name, email:this.state.email }, () => {
      this.setState( { done:true } )      
    } )
  }

  render() {
    let structure = this.props.structure || {}
    return ( 
     <Fragment>
        <div id="register" className="products-wrapper" 
             style={{ 
                backgroundColor:structure.backgroundColor || '#fff', 
                color:structure.color || '#000',
                paddingBottom:'60px'
        }} >
          <div className="products" style={{ textAlign:'left', padding:'5px 30px 0 30px' }} >
            <h3 style={{ lineHeight:'48px', boxSizing:'border-box', margin:'10px 0', userSelect:'none' }} >Subscribe to our newsletter</h3>
            { this.state.done &&
              <Fragment>
                <p>
                  Thank you!
                </p>
                <p>
                  You have now subscribed to our newsletter.
                </p>
              </Fragment>
            }
            { !this.state.done &&
              <Fragment>
                <p>
                  Subscribe to our newsletter to get updated on the latest opportunities.
                </p>
                <div style={{ display:'inline-block', padding:'10px 15px 10px 0' }} >
                  Name<br/>
                  <input 
                    name="name" type="text" value={ this.state.name } onChange={ e => this.updateInputValue( e, 'name' ) } 
                    style={{ padding:'8px', fontFamily:'Montserrat', width:'205px', margin:'8px 0 0 0' }} 
                  />
                </div>
                <div style={{ display:'inline-block', padding:'10px 15px 10px 0' }} >
                  Email
                  <input 
                    name="email" type="email" value={ this.state.email } onChange={ e => this.updateInputValue( e, 'email' ) } 
                    style={{ display:'block', padding:'8px', fontFamily:'Montserrat', width:'205px', margin:'8px 0 0 0' }} 
                  />
                </div>
                <div onClick={ () => { this.send() } } style={{ display:'inline-block', padding:'10px 10px 12px 0', verticalAlign:'bottom' }} >
                  <ActionButton title="Subscribe" />
                </div>
              </Fragment>
            }
          </div>
        </div>
      </Fragment>
    ) 
  }

}

export default Register

