import React, { Fragment } from 'react';
import parse from 'html-react-parser';
import NewsCardBySlug from '../News/NewsCardBySlug';

const NewsBannerTwoColumns = props => {
  return (
  <div className="products-wrapper" style={{ 
    backgroundColor:props.structure.backgroundColor, 
    color:props.structure.color, 
    padding:'30px 0 0 0', 
    boxSizing:'border-box',
  }} >
    <div className="products"  >
      { props.structure.titleFi && 
      <h1 style={{ padding:'0px 30px 20px 30px', margin:'0', boxSizing:'border-box' }}>{ props.structure.titleFi }</h1>
      }
      { props.structure.column1Slug && 
      <div className="two" style={{ padding:'0 30px', margin:'0', boxSizing:'border-box' }}>
        <NewsCardBySlug slug={ props.structure.column1Slug } />
      </div>
      }
      { props.structure.column2Slug && 
      <div className="two" style={{ padding:'0 30px', margin:'0', boxSizing:'border-box' }}>
        <NewsCardBySlug slug={ props.structure.column2Slug } />
      </div>
      }
    </div>
  </div>
  )
}

export default NewsBannerTwoColumns
