import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom';
//import { BrowserRouter as Router } from 'react-router-dom'
import Data from './Data/Data-dynamic';
import App from './App/App'
import Store from './Store/Store'

Store.init( () => {  

	const lang = Store.get( 'lang' ) || navigator.language || navigator.userLanguage

	//Store.set( 'lang', lang === 'fi' ? 'fi' : 'en' )
	Store.set( 'lang', 'en' )

	Data().fetch( ( data ) => {
		//console.log( 'index.js data fetched', data )
		ReactDOM.render((
		    <App/>
		), document.getElementById( "root" ) )

	} )
} )

// Bundling React to another app as a module / component / widget
// https://stackoverflow.com/questions/37239731/writing-embeddable-javascript-plugin-with-react-webpack
// https://frontarm.com/james-k-nelson/how-to-integrate-react-into-existing-app/