import React, { Fragment, useState } from 'react';
//import parseHtml from '../parseHtml/parseHtml';
import EditableColumn from '../EditableColumn/EditableColumn'

const TwoColumnsIcons = props => {

  return (
  <div className="products-wrapper" style={{ backgroundColor:props.structure.backgroundColor, color:props.structure.color, boxSizing:'border-box', padding:'40px 0 10px 0' }} >
    <div className="products" style={{ maxWidth:'900px' }} >
      <h1 style={{ padding:'0px 30px 20px 30px', margin:'0', boxSizing:'border-box' }}>{ props.page.title }</h1>
      { props.structure.column1Icon 
        ? <div className="two" style={{ position:'relative', padding:'20px 30px', border:'1px solid #ccc', borderRadius:'10px', margin:'0 5%', minHeight:'240px', backgroundColor:props.structure.column1BackgroundColor, boxSizing:'border-box' }}>
            <div style={{ position:'absolute', left:'50%', top:'50%', transform:'translate(-50%,-50%)' }} >
              <span style={{ fontSize:'150px', color:'#90a4ae' }}  className="material-icons" >{ props.structure.column1Icon }</span>
            </div>
          </div>
        : <EditableColumn 
            tagName="div"
            className="two"
            columnName="column1"
            style={{ padding:'0 30px', margin:'0', backgroundColor:props.structure.column1BackgroundColor, boxSizing:'border-box' }}
            showCommands={ true }
            showHovers={ true }
            {...props}
          />
      }
      { props.structure.column2Icon
        ? <div className="two" style={{ position:'relative', padding:'20px 30px', border:'1px solid #ccc', borderRadius:'10px', margin:'0 5%', minHeight:'240px', backgroundColor:props.structure.column2BackgroundColor, boxSizing:'border-box' }}>
            <div style={{ position:'absolute', left:'50%', top:'50%', transform:'translate(-50%,-50%)' }} >
              <span style={{ fontSize:'150px', color:'#90a4ae' }}  className="material-icons" >{ props.structure.column2Icon }</span>
            </div>
          </div>
        : <EditableColumn 
            tagName="div"
            className="two"
            columnName="column2"
            style={{ padding:'0 30px', margin:'0', backgroundColor:props.structure.column2BackgroundColor, boxSizing:'border-box' }}
            showCommands={ true }
            showHovers={ true }
            {...props}
          />

      }
    </div>
  </div>
  )
}

export default TwoColumnsIcons
