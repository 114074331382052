import React,{ Fragment } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components'
import Store from '../Store/Store';
import Data from '../Data/Data-dynamic';
import EmployeesCard from '../Employees/EmployeesCard';
import parseHtml from '../parseHtml/parseHtml';

const Grid = styled.div`
  display: grid;

  width:100%,
  max-width:100%,
  box-sizing:border-box;

  grid-template-columns:auto;
  @media only screen and ( min-width: 640px) {
	  grid-template-columns:auto auto;
  }
  @media only screen and ( min-width: 1037px) {
	  grid-template-columns:auto auto auto auto;
  }
`;

const FirstCell = styled.div`
  grid-column-start:1; 
  grid-column:1 / span 1;
  @media only screen and ( min-width: 640px) {
  	grid-column:1 / span 2;
  }
  @media only screen and ( min-width: 1037px) {
	  grid-column:1 / span 4;
  }
`;

class EmployeesList extends React.Component {

	constructor( props ) {
		super( props );
		this.state = { 
			tmp:null,
			blogs:Data.employees || [],
		};
	}

	componentDidMount() {
	}

	render() {
		let limit = this.props.limit || 4
		let employees = Store.get( 'employees' ) || []
		if ( employees === null || employees === null || employees.length === 0 ) {
			return null
		}
		let department = null

		return ( 
        	<div className="products" style={{ maxWidth:'900px' }} >
		    	<Grid style={{ backgroundColor:this.props.backgroundColor || '#fff', color:this.props.color || '#000' , verticalAlign:'top', textAlign:'left', marginLeft:'20px', marginRight:'40px', boxSizing:'border-box', }} >

		    		{ employees.map( ( row, i ) => {
		    			//console.log( 'employeesRow', row )
		    			if ( i >= Number( limit ) ) return <Fragment key={ row.id  + '-' + i } />

	    				let department_title, copy
	    				if ( department !== row.department ) {
	    					department = row.department
							department_title = row.department
							copy = row.employee_group_copy
	    				}

	    				return (
	    					<Fragment key={ row.id }  >
	    						{ department_title &&
	    							<FirstCell>
	    								<h2 style={{ marginLeft:'10px' }} >{ department_title }</h2>
	    							</FirstCell>
	    						}
	    						{ copy && 
	    							<FirstCell style={{ marginLeft:'5px' }} >
			    						{ parseHtml( copy ) }
	    							</FirstCell>
	    						}
		    					<div key={ row.id  + '-' + i } className="blogs-list-block" style={{ position:'relative', gridColumnStart:copy || department_title ? '1' : null }} >
		    						<div style={{ margin:'0 auto', textAlign:'center' }} >
							    		<EmployeesCard 
							    			row={ row }
							    			style={{ display:'inline-block' }}
							    		/>
		    						</div>
	    						</div>

	    					</Fragment>
				    	)




			    	} ) }

			    </Grid>
			</div>
		)
	}
}

export default EmployeesList
