import React, { Fragment, useState, useEffect } from 'react';
import Store from '../Store/Store';
import ActionButton from '../ActionButton/ActionButton';


const Cookies = props => {

  const cookies = Store.get( 'cookies' )
  const lang = Store.get( 'lang' )

  const [ update, setUpdate ] = useState(0);

  const setAllCookies = () => {
    Store.set( 'cookies', 'all' )
    setUpdate( update + 1 )
  }

  const setEssentialCookies = () => {
    Store.set( 'cookies', 'essential' )
    setUpdate( update + 1 )
  }

  if ( cookies ) {
    return null
  } else {
    return (
      <div style={{ position:'fixed', top:0, left:0, width:'100%', height:'100%', background:'rgba(255,255,255,0.85)' }} >
      <div style={{ 
          position:'fixed', top:'50%', left:'50%', transform:'translate(-50%, -50%)',
          width:'100%', maxWidth:'480px',
          border:'2px solid rgba(0,0,0,0.5)',
          boxShadow:'0 4px 6px rgba(0,0,0,0.15)',
          padding:'20px 20px', margin:'10px',
          fontSize:'1.00rem',
          background:'#fff', textAlign:'center'
      }}>
        { lang === 'fi' 
        ? 'Keräämme käyttäytymiseen liittyviä tietoja ja käytämme evästeitä ja ohjelmistotyökaluja, jotta voimme mitata sivuston käyttöä ja siihen liittyviä tietoja.' 
        : 'We collect information about user behavior on our website and to do that we use various online analytic tools and cookies.'
        }
         

        <div style={{ position:'relative', width:'100%', textAlign:'center', padding:'10px 0 0 0' }} >
          <div style={{ display:'inline-block', paddingTop:'10px' }} >
            <div onClick={ setAllCookies } style={{ display:'inline-block', cursor:'pointer', padding:'10px' }} >
              <ActionButton title={ lang === 'fi' ? "Hyväksy evästeet" : "Accept Cookies" } style={{ 
                  display:'inline-block', 
                  //background:'linear-gradient(180deg, #6b6b72 0%, #446 100%)' 
                }} />
            </div>
            <div onClick={ setEssentialCookies } style={{ display:'inline-block', cursor:'pointer', padding:'10px' }} >
              <ActionButton title={ lang === 'fi' ? "Hylkää" : "Reject" } style={{ 
                  display:'inline-block', 
                  background:'linear-gradient(180deg, #90a4ae 0%, #455a64 100%)' 
                }} />
            </div>
          </div>
        </div>

      </div>
      </div>
    )
  }
}

export default Cookies
