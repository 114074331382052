import * as THREE from 'three'
import React, { Fragment, Suspense, useRef, useEffect, useState } from "react"
import { Canvas, useThree } from "react-three-fiber"
import { OrbitControls } from "@react-three/drei"
import TiltedCubes from '../models/TiltedCubes';

export default function Background( props ) {

  const [ mesh, setMesh ] = useState( 0 )

  useEffect( () => {
  }, [ mesh ] )

  const basicStyle = { 
    position:'absolute', top:0, left:0, 
    width:'100%', height:'28vh', minHeight:'300px', 
    margin:'0', 
    background: '#90a4ae' 
  }

  const additionalStyle = props.style || {}

  const mergedStyle = { ...basicStyle, ...additionalStyle }


  return (
    <Fragment>

      <Canvas 
        concurrent 
        style={ mergedStyle } 
        pixelRatio={ window.devicePixelRatio }
        camera={{ zoom:3.8, position: [0, 0, 1.75] }}
      >
        <ambientLight intensity={ 0.7 } />
        <spotLight 
          intensity={ 1.3 } 
          angle={ 0.1 } 
          penumbra={ 1 } 
          position={ [ -5, 25, 20 ] } 
          castshadow
        />
        <spotLight 
          intensity={ 1.3 } 
          angle={ 0.1 } 
          penumbra={ 1 } 
          position={ [ 5, 25, 20 ] } 
          castshadow
        />
        <spotLight 
          intensity={ 1.3 } 
          angle={ 0.1 } 
          penumbra={ 1 } 
          position={ [ 0, 15, 10 ] } 
          castshadow
        />

        <fogExp2 attach="fog" args={[ '#54433a', 0.0 ]} />

        <Suspense fallback={null} >
          <TiltedCubes />
        </Suspense>

        <OrbitControls 
          //minPolarAngle={ -Math.PI*2 } 
          //maxPolarAngle={ Math.PI*2 } 
          minAzimuthAngle={ 0 }
          maxAzimuthAngle={ 0 }
          enableDamping={ true }
          dampingFactor={ 0.05 } 
          //autoRotate={ false }
          //autoRotateSpeed={ 0.5 }
          enableZoom={ true } 
          minDistance={ 1 }
          maxDistance={ 3 }
          enablePan={ false } 
        />


      </Canvas>


    </Fragment>
  )
}
