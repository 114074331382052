import React, { Fragment } from 'react';
import NewsList from '../News/NewsList';
import NewsListAll from '../News/NewsListAll';

const News = props => (
  <Fragment>
    <div id="news" className="products-wrapper" style={{ 
      backgroundColor:'#eee', 
      color:props.structure.color, 
      padding:'20px 0 0 0',
      margin:'0 0 0 0',
    }} >

      <div className="products" style={{ maxWidth:'900px', padding:'0px 30px 0 30px' }} >
        <h1 style={{ margin:'0', lineHeight:'48px', boxSizing:'border-box', userSelect:'none' }} >News</h1>

        { props.limit > 4 
        ? <NewsListAll
          limit={ props.limit || 4 }
          companyName={ props.companyName }
          backgroundColor={ props.structure.backgroundColor }
          color={ props.structure.color }
          backFromPageTo="back" 
        />
        : <NewsList
          limit={ props.limit || 4 }
          companyName={ props.companyName }
          backgroundColor={ props.structure.backgroundColor }
          color={ props.structure.color }
          backFromPageTo="back" 
        />
        }

      </div>

    </div>
  </Fragment>
)

export default News
