import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; 
import parseHtml from '../parseHtml/parseHtml';
import styled from 'styled-components'

import { LineChart, Line, Legend, Area, AreaChart, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';

const dataSet = [
{name: '2015-10-04','NS Quant': 100.832065,'Long only comparison': 99.7675469061663,'HFRXGL': 100.119410463029,'BTOP50': 95.9891650284785},
{name: '2015-11-02','NS Quant': 103.935112090225,'Long only comparison': 103.249392961076,'HFRXGL': 100.354891236274,'BTOP50': 98.7516139847768},
{name: '2015-12-01','NS Quant': 104.787501096891,'Long only comparison': 99.5717673920695,'HFRXGL': 99.5841509749071,'BTOP50': 97.1645841549752},
{name: '2016-01-04','NS Quant': 106.276200948942,'Long only comparison': 94.5249559679241,'HFRXGL': 97.5266168427206,'BTOP50': 99.9524084678278},
{name: '2016-02-01','NS Quant': 113.406885783738,'Long only comparison': 90.7496743521773,'HFRXGL': 95.3830737756253,'BTOP50': 102.23670010303},
{name: '2016-03-01','NS Quant': 119.718108070709,'Long only comparison': 95.2677425678412,'HFRXGL': 95.3747233935953,'BTOP50': 99.2872480171044},
{name: '2016-04-01','NS Quant': 119.415764622895,'Long only comparison': 98.6954168266744,'HFRXGL': 96.247338315728,'BTOP50': 97.1607116106014},
{name: '2016-05-02','NS Quant': 125.032391925129,'Long only comparison': 103.773058509651,'HFRXGL': 96.5755083295061,'BTOP50': 95.6175026776606},
{name: '2016-06-01','NS Quant': 119.14717173657,'Long only comparison': 105.402064003658,'HFRXGL': 97.1057575884098,'BTOP50': 97.6115573029563},
{name: '2016-07-01','NS Quant': 119.38269682967,'Long only comparison': 107.735766667341,'HFRXGL': 97.5975950899755,'BTOP50': 99.0317619974992},
{name: '2016-08-01','NS Quant': 117.109571585899,'Long only comparison': 105.824345995566,'HFRXGL': 98.6330424616928,'BTOP50': 96.9311104928628},
{name: '2016-09-01','NS Quant': 122.779182077639,'Long only comparison': 106.823632066706,'HFRXGL': 98.8326165922092,'BTOP50': 95.2968967671388},
{name: '2016-10-03','NS Quant': 122.792199870733,'Long only comparison': 110.204487567754,'HFRXGL': 99.4213185253226,'BTOP50': 93.2358878878022},
{name: '2016-11-01','NS Quant': 126.165524070021,'Long only comparison': 107.336007082771,'HFRXGL': 98.50695169304,'BTOP50': 92.4650477393003},
{name: '2016-12-01','NS Quant': 127.248358560668,'Long only comparison': 108.014445316611,'HFRXGL': 99.6150473884181,'BTOP50': 92.8483277232394},
{name: '2017-01-03','NS Quant': 126.435315923446,'Long only comparison': 110.290473070352,'HFRXGL': 100.644649492714,'BTOP50': 91.5340065446},
{name: '2017-02-01','NS Quant': 128.207649478784,'Long only comparison': 113.903117920448,'HFRXGL': 101.151517681934,'BTOP50': 92.0526217634956},
{name: '2017-03-01','NS Quant': 127.504552822217,'Long only comparison': 117.577672993878,'HFRXGL': 102.542691328128,'BTOP50': 91.2042288184561},
{name: '2017-04-03','NS Quant': 125.42212849318,'Long only comparison': 115.477263038918,'HFRXGL': 102.042503444533,'BTOP50': 90.6833716001863},
{name: '2017-05-01','NS Quant': 129.347737217845,'Long only comparison': 116.292561136672,'HFRXGL': 102.561062168594,'BTOP50': 90.7820195726545},
{name: '2017-06-01','NS Quant': 129.615754557084,'Long only comparison': 117.837839635617,'HFRXGL': 103.092146465701,'BTOP50': 88.4218056859142},
{name: '2017-07-03','NS Quant': 135.531120535603,'Long only comparison': 114.865867457707,'HFRXGL': 103.08630119828,'BTOP50': 88.9764967129232},
{name: '2017-08-01','NS Quant': 135.048851408316,'Long only comparison': 119.370135627196,'HFRXGL': 104.085841927268,'BTOP50': 90.6424041570745},
{name: '2017-09-01','NS Quant': 135.860598533748,'Long only comparison': 120.111361450305,'HFRXGL': 104.469124462444,'BTOP50': 88.8284228451583},
{name: '2017-10-02','NS Quant': 136.018000098193,'Long only comparison': 121.358739633655,'HFRXGL': 105.092062961881,'BTOP50': 91.5642735361527},
{name: '2017-11-01','NS Quant': 138.151101913204,'Long only comparison': 125.631037153121,'HFRXGL': 105.877833910901,'BTOP50': 91.3028767909244},
{name: '2017-12-01','NS Quant': 139.178285906889,'Long only comparison': 129.275196087138,'HFRXGL': 105.66573420734,'BTOP50': 92.0885956625465},
{name: '2018-01-02','NS Quant': 144.931225811422,'Long only comparison': 132.843277376817,'HFRXGL': 106.8523234938,'BTOP50': 94.8729550672752},
{name: '2018-02-01','NS Quant': 148.941798563243,'Long only comparison': 140.312875585265,'HFRXGL': 109.205461149848,'BTOP50': 89.570422728982},
{name: '2018-03-01','NS Quant': 156.729533914806,'Long only comparison': 134.321591027287,'HFRXGL': 106.17260239656,'BTOP50': 89.7317447748676},
{name: '2018-04-02','NS Quant': 153.654408071473,'Long only comparison': 132.664148901665,'HFRXGL': 105.218153730533,'BTOP50': 90.0527583216392},
{name: '2018-05-01','NS Quant': 157.307686973757,'Long only comparison': 136.759621236156,'HFRXGL': 105.504571834162,'BTOP50': 88.1329954028822},
{name: '2018-06-01','NS Quant': 165.955903273246,'Long only comparison': 139.074314498162,'HFRXGL': 105.867813452466,'BTOP50': 88.6535468939646},
{name: '2018-07-02','NS Quant': 164.800710217902,'Long only comparison': 142.368606440771,'HFRXGL': 105.80769070185,'BTOP50': 88.1755933909934},
{name: '2018-08-01','NS Quant': 165.980391840691,'Long only comparison': 140.487775285624,'HFRXGL': 105.531293056657,'BTOP50': 89.6241288049022},
{name: '2018-09-02','NS Quant': 167.960124669686,'Long only comparison': 144.646748440662,'HFRXGL': 105.933781470502,'BTOP50': 89.6241288049022},
{name: '2018-10-01','NS Quant': 161.468295543381,'Long only comparison': 147.611960450331,'HFRXGL': 105.30082251263,'BTOP50': 87.5982785521167},
{name: '2018-11-01','NS Quant': 172.771616176272,'Long only comparison': 136.611005731252,'HFRXGL': 102.316395975116,'BTOP50': 87.2647301906616},
{name: '2018-12-03','NS Quant': 181.211308432245,'Long only comparison': 131.29519400565,'HFRXGL': 101.573211974448,'BTOP50': 87.8518282995351},
{name: '2019-01-02','NS Quant': 181.6364684627,'Long only comparison': 121.398319897486,'HFRXGL': 99.6359233434931,'BTOP50': 86.3273706340883},
{name: '2019-02-01','NS Quant': 183.193508143807,'Long only comparison': 132.308369546125,'HFRXGL': 101.437100747359,'BTOP50': 86.7684330564463},
{name: '2019-03-01','NS Quant': 186.106376601945,'Long only comparison': 134.887301135107,'HFRXGL': 102.262118491921,'BTOP50': 89.3505029721778},
{name: '2019-04-01','NS Quant': 194.460824332866,'Long only comparison': 140.599847557781,'HFRXGL': 102.362323076281,'BTOP50': 92.1830653634535},
{name: '2019-05-01','NS Quant': 198.395883403662,'Long only comparison': 143.74619688703,'HFRXGL': 102.613669575383,'BTOP50': 90.6437289748865},
{name: '2019-06-03','NS Quant': 203.647078184372,'Long only comparison': 132.441753456679,'HFRXGL': 102.039998329924,'BTOP50': 92.526498903968},
{name: '2019-07-01','NS Quant': 211.549191703428,'Long only comparison': 144.219856145942,'HFRXGL': 103.877082376519,'BTOP50': 95.3517238427461},
{name: '2019-08-01','NS Quant': 216.785924306391,'Long only comparison': 142.796615241977,'HFRXGL': 104.360569496055,'BTOP50': 98.9478908390886},
{name: '2019-09-01','NS Quant': 217.362296214615,'Long only comparison': 144.264556196452,'HFRXGL': 104.750532336855,'BTOP50': 98.9478908390886},
{name: '2019-10-01','NS Quant': 219.141853924255,'Long only comparison': 142.508620032477,'HFRXGL': 105.241534800217,'BTOP50': 93.3802930293181},
{name: '2019-11-01','NS Quant': 219.226318688369,'Long only comparison': 149.020648800721,'HFRXGL': 105.608951609536,'BTOP50': 94.0711345637833},
{name: '2019-12-02','NS Quant': 212.836979464011,'Long only comparison': 149.213319234175,'HFRXGL': 106.449000041752,'BTOP50': 93.7162872082725},
{name: '2020-01-02','NS Quant': 219.198938588861,'Long only comparison': 158.556859391583,'HFRXGL': 108.016366748779,'BTOP50': 93.8470365354179},
{name: '2020-02-03','NS Quant': 224.456852403548,'Long only comparison': 154.915770578063,'HFRXGL': 108.226796375934,'BTOP50': 92.6108796076908},
{name: '2020-03-02','NS Quant': 224.305160075521,'Long only comparison': 149.932729134424,'HFRXGL': 106.89407540395,'BTOP50': 91.6226674289464},
{name: '2020-04-01','NS Quant': 251.83258527518,'Long only comparison': 119.759536837712,'HFRXGL': 100.178698175442,'BTOP50': 91.8853889919868},
{name: '2020-05-01','NS Quant': 255.530868949054,'Long only comparison': 133.468397736492,'HFRXGL': 103.452047931193,'BTOP50': 91.5566303564676},
{name: '2020-06-01','NS Quant': 263.740440394602,'Long only comparison': 150.750866073097,'HFRXGL': 104.940921047138,'BTOP50': 91.0832627613075},
{name: '2020-07-01','NS Quant': 253.247953831792,'Long only comparison': 158.478231696959,'HFRXGL': 106.850653417394,'BTOP50': 93.8530491701035},
{name: '2020-08-03','NS Quant': 260.172343743257,'Long only comparison': 170.326537135907,'HFRXGL': 108.469792493007,'BTOP50': 93.7409492013896},
{name: '2020-09-01','NS Quant': 262.645007419226,'Long only comparison': 181.195396397751,'HFRXGL': 110.043004467454,'BTOP50': 92.4226535693139},
{name: '2020-10-01','NS Quant': 242.50643532675,'Long only comparison': 170.808591613868,'HFRXGL': 109.811698885224,'BTOP50': 92.4226535693139},
{name: '2020-11-02','NS Quant': 249.002134224007,'Long only comparison': 166.538137698002,'HFRXGL': 109.562857500731,'BTOP50': 94.3990779268027},
{name: '2020-12-01','NS Quant': 260.887077268972,'Long only comparison': 180.322879449372,'HFRXGL': 112.826186798046,'BTOP50': 98.4392627083148},
{name: '2021-01-01','NS Quant': 262.287992609769,'Long only comparison': 187.321657907511,'HFRXGL': 115.275353847439,'BTOP50': 97.3847077299042},
{name: '2021-02-01','NS Quant': 256.214307472821,'Long only comparison': 188.527419699417,'HFRXGL': 115.467412634128,'BTOP50': 100.17181867932}
]


const Ahover = styled.div`
  a { text-decoration:none; color:black; display:inline-block; padding-bottom:0; border-bottom:2px solid rgba(0,0,0,0); }
  a:hover { border-bottom:2px solid rgb(32, 190, 225); }
`;

const Graph = props => {
  let title = props.page.title
  if ( props.title !== undefined ) {
    title = props.title
  }

  const [ data, setData ] = useState( dataSet )

  
  useEffect( () => {

    let d = JSON.parse( props.structure.column )
    //console.log( 'd', d )
    let d2 = []
    d.map( a => {
      let row = { 'name':a[ 0 ].value,'NS Quant':a[ 1 ].value,'Long only comparison':a[ 2 ].value,'HFRXGL':a[ 3 ].value,'BTOP50':a[ 4 ].value }
      //console.log( 'row', row )
      d2.push( row )
    } )
    //console.log( 'd2', d2 )
    setData( d2 )

    //console.log( 'data', data )

  }, [ props.structure.column ] )
  
  //let data = d2

  //console.log( 'Generic props.structure', props.structure )
  //console.log( 'Generic props.structure', JSON.parse( props.structure.column ) )
  return (
  <div className="products-wrapper" style={{ 
    backgroundColor:props.structure.backgroundColor || '#fff', 
    color:props.structure.color || '#000', 
    padding:'0', 
    boxSizing:'border-box', 
  }} >
    <div className="products" style={{ maxWidth:'900px' }}  >
      { title && 
      <h1 style={{ padding:'30px 30px 10px 30px', margin:'0', boxSizing:'border-box' }}>{ parseHtml( title  || '' ) }</h1>
      }

      {/*      
      <Ahover style={{ padding:'10px 30px', margin:'0', fontSize:'15px', boxSizing:'border-box' }}>
        { parseHtml( props.structure.column || '' ) }
      </Ahover>
      */}

      <div style={{ width:'100%', height:'480px', padding:'20px 0' }} >
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={ data }
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="NS Quant" stroke="#01579b" strokeWidth="2" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="Long only comparison" stroke="#004d40" strokeWidth="2" />
          <Line type="monotone" dataKey="HFRXGL" stroke="#558b2f" strokeWidth="2" />
          <Line type="monotone" dataKey="BTOP50" stroke="#ef6c00" strokeWidth="2" />
        </LineChart>
      </ResponsiveContainer>
    </div>



    </div>
  </div>
  )
}
export default Graph
