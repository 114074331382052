import React, { useEffect } from 'react';
import Store from '../Store/Store';
import Blocks from '../LongScroll/Blocks';

const LongScroll = props => {

	/*
    let user = Store.get( 'user' )
    if ( user === null || user === undefined ) {
      props.history.replace( '/login/' )
    }
    */

	useEffect( () => {
		document.body.scrollTop = 0
		document.documentElement.scrollTop = 0
	}, [ props.match.params.section ] )

	// slug for tabs
	let slug = null
	if ( props && props.match && props.match.params && props.match.params.slug ) {
		slug = props.match.params.slug
	}
	// "sub" slug for sub-tab-pages
	let sub = null
	if ( props && props.match && props.match.params && props.match.params.sub ) {
		sub = props.match.params.sub
	}

	let allPages = Store.get( 'pages' ) || []
	let pagesObject = Store.get( 'pagesObject' ) || {}
	//let pages = allPages.filter( a => a.section === props.match.params.section )
	let pages = pagesObject[ props.match.params.section ]

	console.log( 'LongScroll', props.match.params.section, slug, sub )
	// <Blocks user={ props.user } history={ props.history } section={ props.match.params.section } pages={ pages } pagesObject={ pagesObject } newslimit={ props.newslimit } slug={ slug } sub={ sub } />

	return(
  		<Blocks pages={ pages } pagesObject={ pagesObject } section={ props.match.params.section } slug={ slug } sub={ sub } {...props} />
  	)
}




export default LongScroll
