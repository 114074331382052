import React, { Fragment, useState } from 'react';

import HeroFront from '../LongScroll/HeroFront';
import Hero from '../LongScroll/Hero';
import HeroTabs from '../LongScroll/HeroTabs';

import Generic from '../LongScroll/Generic';
import GenericWithHeader from '../LongScroll/GenericWithHeader';

import TwoColumns from '../LongScroll/TwoColumns';
import TwoColumnsIcons from '../LongScroll/TwoColumnsIcons';

import ThreeColumns from '../LongScroll/ThreeColumns';
import FourColumns from '../LongScroll/FourColumns';

import Graph from '../LongScroll/Graph';
import Figures from '../LongScroll/Figures';
import BidsAndAsks from '../LongScroll/BidsAndAsks';
import Tabs from '../LongScroll/Tabs';

import Employees from '../LongScroll/Employees';
import Blogs from '../LongScroll/Blogs';
import Register from '../Register/Register';
import Subscribe from '../Subscribe/Subscribe';

import News from '../LongScroll/News';
import NewsBannerOneColumn from '../LongScroll/NewsBannerOneColumn';
import NewsBannerTwoColumns from '../LongScroll/NewsBannerTwoColumns';
import NewsBannerLeft from '../LongScroll/NewsBannerLeft';
import NewsBannerRight from '../LongScroll/NewsBannerRight';


const Block = props => {

  //const [ currentPage, setCurrentPage ] = useState( props.page )

  //console.log( 'Block props.page', props.page )
  //if ( !props.page || props.page.structure ) return null
  const pageStructure = JSON.parse( props.page.structure || '{}' )

  const [ structure, setStructure ] = useState( pageStructure )

  //console.log( 'Block', props.page.template )
  //console.log( 'sub', props.sub, props.slug )
  //return (
  // {...props}
  // <Tabs user={ props.user } key={ props.page.id } back={ props.sub } history={ props.history } slug={ props.slug } sub={ props.sub } section={ props.section } page={ props.page } structure={ structure } files={ props.page.files } />
  // <Generic structure={ structure } setStructure={ setStructure } user={ props.user } key={ props.page.id } title={ props.title } page={ props.page } />
  // <Tabs key={ props.page.id } back={ props.sub } structure={ structure } files={ props.page.files } {...props} />

    if ( props.page.template === 'hero-front' ) {
      return (
        <HeroFront key={ props.page.id } section={ props.section } page={ props.page } structure={ structure } setStructure={ setStructure } files={ props.page.files } {...props} />
      )

    } else if ( props.page.template === 'tabs' ) {
      return (
        <Tabs key={ props.page.id } back={ props.sub } structure={ structure } setStructure={ setStructure } files={ props.page.files } {...props} />
      )
    } else if ( props.page.template === 'generic' ) {
      return (
        <Generic key={ props.page.id } structure={ structure } setStructure={ setStructure } {...props} />
      )
    } else if ( props.page.template === 'two-columns' ) {
      return (
        <TwoColumns key={ props.page.id } structure={ structure } setStructure={ setStructure } {...props} />
      )
    } else if ( props.page.template === 'two-columns-icons' ) {
      return (
        <TwoColumnsIcons key={ props.page.id } structure={ structure } setStructure={ setStructure } {...props} />
      )

    } else if ( props.page.template === 'generic-with-header' ) {
      return (
        <Fragment>
          <Hero key={ props.page.id + '-hero' } back={ props.sub } structure={ structure } setStructure={ setStructure } files={ props.page.files } {...props} />
          <Generic key={ props.page.id + '-generic' } structure={ structure } setStructure={ setStructure } {...props} />
        </Fragment>
      )
    } else if ( props.page.template === 'hero' ) {
      return (
        <Hero key={ props.page.id } back={ props.sub } structure={ structure } setStructure={ setStructure } files={ props.page.files } {...props} />
      )
    } else if ( props.page.template === 'hero-tabs' ) {
      return (
        <HeroTabs key={ props.page.id } back={ props.sub } structure={ structure } setStructure={ setStructure } files={ props.page.files } {...props} />
      )
    } else if ( props.page.template === 'three-columns' ) {
      return (
        <ThreeColumns key={ props.page.id } structure={ structure } setStructure={ setStructure } {...props} />
      )
    } else if ( props.page.template === 'graph' ) {
      return (
        <Graph key={ props.page.id } structure={ structure } setStructure={ setStructure } {...props} />
      )
    } else if ( props.page.template === 'figures' ) {
      return (
        <Figures key={ props.page.id } structure={ structure } setStructure={ setStructure } {...props} />
      )
    } else if ( props.page.template === 'bidsAndAsks' ) {
      return (
        <BidsAndAsks key={ props.page.id } structure={ structure } setStructure={ setStructure } {...props} />
      )
    } else if ( props.page.template === 'four-columns' ) {
      return (
        <FourColumns key={ props.page.id } structure={ structure } setStructure={ setStructure } {...props} />
      )
    } else if ( props.page.template === 'register' ) {
      return (
        <Register key={ props.page.id } structure={ structure } setStructure={ setStructure } {...props} />
      )
    } else if ( props.page.template === 'subscribe' ) {
      return (
        <Subscribe key={ props.page.id } structure={ structure } setStructure={ setStructure } {...props} />
      )
    } else if ( props.page.template === 'news' ) {
      return (
        <News key={ props.page.id } limit={ props.newslimit } structure={ structure } setStructure={ setStructure } {...props} />
      )
    } else if ( props.page.template === 'news-banner-1-column' ) {
      return (
        <NewsBannerOneColumn key={ props.page.id } limit={ props.newslimit } structure={ structure } setStructure={ setStructure } {...props} />
      )
    } else if ( props.page.template === 'news-banner-2-columns' ) {
      return (
        <NewsBannerTwoColumns key={ props.page.id } limit={ props.newslimit } structure={ structure } setStructure={ setStructure } {...props} />
      )
    } else if ( props.page.template === 'news-banner-left' ) {
      return (
        <NewsBannerLeft key={ props.page.id } limit={ props.newslimit } structure={ structure } setStructure={ setStructure } {...props} />
      )
    } else if ( props.page.template === 'news-banner-right' ) {
      return (
        <NewsBannerRight key={ props.page.id } limit={ props.newslimit } structure={ structure } setStructure={ setStructure } {...props} />
      )
    } else if ( props.page.template === 'employees' ) {
      return (
        <Employees key={ props.page.id } structure={ structure } setStructure={ setStructure } {...props} />
      )
    } else if ( props.page.template === 'blog' ) {
      return (
        <Blogs key={ props.page.id } structure={ structure } setStructure={ setStructure } {...props} />
      )
    } else {
      return (
        <Generic key={ props.page.id } structure={ structure } setStructure={ setStructure } {...props} />
      )
    }
  //)
}

export default Block
