import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import Store from '../Store/Store';
import Block from '../LongScroll/Block';
import parse from 'html-react-parser';
import styled from 'styled-components'

const ContainerDiv = styled.div`
  text-align:center; 
  width:100%; 
  max-width:260px;
  margin:0 auto;
  @media only screen and ( min-width: 961px) {
    max-width:100%;
  }
`;

const TabDiv = styled.div`
  display:table; 
  margin:4px auto;
  font-size:0.9rem;
  white-space:normal; padding:2px 0 0 0;
  @media only screen and ( min-width: 961px) {
    display:inline-block; 
    margin:8px;
    font-size:0.8rem; 
  }
`;

const ShowTabs = props => {
  let prevSlug = null
  const lang = Store.get( 'lang' )
  const cookies = Store.get( 'cookies' )

  //console.log( 'Tabs' )
  if ( cookies === 'all' ) {
    //ReactGA.pageview( window.location.pathname + window.location.search );
  }

  return(
  <div style={{ 
    position:'relative', width:'100%', 
    padding:'2px', 
    textAlign:'center', 
    color:'black', fontWeight:'700', 
    whiteSpace:'normal', boxSizing:'border-box',  
  }} >
    <ContainerDiv>
      { props.pages.map( ( a, i ) => {
        let structure = JSON.parse( a.structure )
        if ( prevSlug === a.slug ) { 
          return <Fragment key={ a.id } /> 
        } else {
          prevSlug = a.slug
          return (
            <Link key={ a.id }  to={ '/' + props.section + '/' + a.slug } draggable="false" >
            <TabDiv 
              key={ a.id } 
              style={{ 
                color:props.index === i ? 'black' : '#999', 
                borderBottom:props.index === i ? ( '4px solid ' + ( process.env.REACT_APP_ACCENT_COLOR || '#80d8ff' ) ) : '4px solid rgba(0,0,0,0)', 
              }}
            > 
              { parse( ( lang === 'fi' ? structure.titleFi : a.title ) || a.title ) }
            </TabDiv>
            </Link>
          ) 
        }
      } ) }
    </ContainerDiv>
  </div>
  )  
}

const Tabs = props => {
  let allPages = Store.get( 'pages' ) || []
  // get and filter pages for tabs
  let pages = allPages.filter( a => a.section === props.structure.tabsSection )
  const tabsPage = pages.filter( a => a.template === 'tabs' )
  pages = pages.filter( a => a.ordinal > tabsPage[ 0 ].ordinal )

  // get tabIndex
  let tabIndex = 0
  let prevSlug = null
  let subContent = []
  if ( props.slug ) {
    pages.forEach( ( a, i ) => {
      if ( a.slug === props.slug  ) {
        if ( prevSlug !== a.slug ) {
          tabIndex = i
        }
        subContent.push( a )
      }
      prevSlug = a.slug
    } )
  }

  //console.log( 'subContent', subContent )

  // get current page
  let page = {}
  if ( pages && pages.length > 0 && pages[ tabIndex ] && pages[ tabIndex ].hasOwnProperty( 'structure' ) ) {
    page = pages[ tabIndex ]
  }

  // back from sub-page
  let showBackButton = false
  let mainTitle = page.title
  let linkBackToMain = '/' + props.section + '/' + page.slug

  // get and filter subPages, and replace page  
  if ( props.sub && props.slug && props.structure.subSection && props.structure.subSectionSlug && props.slug === props.structure.subSectionSlug ) {
    let subPages = allPages.filter( a => a.section === props.structure.subSection )
    if ( props.slug ) {
      subPages.forEach( ( a, i ) => {
        if ( a.slug === props.sub ) {
          page = a
          showBackButton = true
        }
      } )
    }
  }

  return (
    <Fragment>

      <div style={{ position:'relative', margin:'5px 0 0 0', textAlign:'center', boxSizing:'border-box' }} >
        <ShowTabs section={ props.section } pages={ pages } index={ tabIndex } />
      </div>

      { subContent.length > 1 
        ? subContent.map( ( a, i ) => {
            return (
              <Block user={ props.user } key={ a.id + '-' + i } page={ a } section={ props.structure.section } />
            )
          } ) 
        : <Block user={ props.user } key={ page.id } page={ page } section={ props.structure.section } />
      }

    </Fragment>
  )
}

export default Tabs
