import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

import Store from '../Store/Store';
import Data from '../Data/Data-dynamic';

//import { useHistory, useLocation } from 'react-router-dom'; // must import Router, else switch doesn't work

export default function Logout( props ) {
  const [ tmp, setTmp ] = useState( 0 );

  let history = useHistory();

  const home = () => {
    history.replace( '/' )
  }

  return (
    <div 
      style={{ 
        //position:'relative', 
        display:'flex', 
        cursor:'pointer', 
        userSelect:'none', 
        padding:'0 12px 4px 14px', 
        margin:'7px 0 0 0',
        lineHeight:'15px',
        //margin:0, 
        background:'rgba(255,255,255,0.8)', 
        borderRadius:'19px',
        //background:'pink', 
        color:'#000', fontWeight:'700', fontSize:'12px', 
        boxShadow:'0 2px 4px rgba(0,0,0,0.3)' 
      }}
      onClick={ () => { 
        Store.set( 'user', null )
        props.setUser( null ) 
        Data().fetch( ( data ) => {
          home()
        } )
      } }
    >
      <div style={{ padding:'12px 5px 0 0', transform:'translateY(-3px)' }} >
      { props.user.last_name + ' ' + props.user.first_name }
      </div>
      <div style={{ transform:'translateY(3px)', fontSize:'12px' }} >
        <span className="material-icons">logout</span>
      </div>
    </div>
  )
}
