import React, { Fragment } from 'react';
import parse from 'html-react-parser';

const FourColumns = props => {
  return (
  <div className="products-wrapper" style={{ 
    backgroundColor:props.structure.backgroundColor, 
    color:props.structure.color, 
    padding:'40px 0 10px 0', 
    boxSizing:'border-box', 
  }} >
    <div className="products"  >
      <h1 style={{ padding:'0px 30px 0 30px', margin:'0', boxSizing:'border-box' }}>{ props.page.title }</h1>
      <div className="four" style={{ width:'40%', padding:'10px 30px', margin:'0', boxSizing:'border-box' }}>
        { parse( props.structure.column1 || '' ) }
      </div>
      <div className="four" style={{ width:'20%', padding:'10px 30px', margin:'0', boxSizing:'border-box' }}>
        { parse( props.structure.column2 || '' ) }
      </div>
      <div className="four" style={{ width:'20%', padding:'10px 30px', margin:'0', boxSizing:'border-box' }}>
        { parse( props.structure.column3 || '' ) }
      </div>
      <div className="four" style={{ width:'20%', padding:'10px 30px', margin:'0', boxSizing:'border-box' }}>
        { parse( props.structure.column4 || '' ) }
      </div>
    </div>
  </div>
  )
}

export default FourColumns
