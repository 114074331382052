import React from 'react';
import Block from '../LongScroll/Block';
import Store from '../Store/Store';

const Blocks = props => {

	/*
    let user = Store.get( 'user' )
    if ( user === null || user === undefined ) {
      props.history.replace( '/login/' )
    }
  */

	let pages = props.pages || []

	// hert-tabs -> return only that page
	if ( props.pages && props.pages.length > 0 && props.pages[ 0 ].template === 'hero-tabs' ) {
	    pages = pages.filter( a => a.template === 'hero-tabs' )
	}

	// tabs exist -> return only page up to tabs page
	if ( props.pages && props.pages.length > 0 ) {
		let tabsOrdinal = 0
		pages.forEach( a => {
			if ( a.template === 'tabs' ) {
				tabsOrdinal = a.ordinal
			}
		} )
	  if ( tabsOrdinal ) {
	    pages = pages.filter( a => a.ordinal <= tabsOrdinal )
	  }
	}

	// tabs exist -> return only page up to tabs page
	if ( props.section && props.section === 'extra' && props.pages && props.pages.length > 0 && props.slug ) {
    pages = pages.filter( a => a.slug === props.slug )
		console.log( 'extra pages', pages )
	}

	// console.log( 'Blocks pages', pages )
	// <Block user={ props.user } key={ a.id + '-' + i } history={ props.history } page={ a } newslimit={ props.newslimit } section={ props.section } slug={ props.slug } sub={ props.sub } limit={ props.newslimit } />
	// <Block key={ a.id + '-' + i } page={ a } {...props} />


	return (
		pages.map( ( a, i ) => {
			return (
				<Block key={ a.id + '-' + i } page={ a } {...props} />
			)
		} ) 
	)

}

export default Blocks
