import React,{ Fragment } from 'react';
import parse from 'html-react-parser';
import Data from '../Data/Data-dynamic';
import BreadCrumbs from '../BreadCrumbs/BreadCrumbs';

class BlogPage extends React.Component {

	constructor( props ) {
		super( props );
		this.state = { 
			tmp:null,
			blogListIndex:0,
			blogs:Data().blogs || [],
		};
	}

	componentDidMount() {
		document.body.scrollTop = 0
		document.documentElement.scrollTop = 0
		let blogs = this.state.blogs
		/*
		Data().fetch( ( data ) => {
			this.setState( { blogs:data.blogs } )
			blogs.forEach( ( a, i ) => {
				if ( a.slug === this.props.match.params.slug ) {
					this.setState( { blogListIndex:i } )
				}
			} )
		} )
		*/
		blogs.forEach( ( a, i ) => {
			if ( a.slug === this.props.match.params.slug ) {
				this.setState( { blogListIndex:i } )
			}
		} )
	}

	render() {

		let backTo = '/activities/'
		if ( this.props.location.state && this.props.location.state.backFromPageTo && this.props.location.state.backFromPageTo === 'back' ) {
			backTo = 'back'
		}

		let blogs = this.state.blogs

		if ( blogs === null || blogs === null || blogs.length === 0 ) {
			return null
		}


		return ( 
			<Fragment>

	    		<BreadCrumbs 
	    			to={ backTo }
	    			history={ this.props.history }
	    			img={ blogs[ this.state.blogListIndex ].img } 
	    			titles={ [ 'Blogs', blogs[ this.state.blogListIndex ].title ] } 
    			/>

		        <div className="products" style={{ maxWidth:'900px' }} >
	    			<div style={{ padding:'0 30px 10px 30px' }} >
				    	<h1 style={{ lineHeight:'50px', margin:'20px 0 0 0', textAlign:'left' }} >{ blogs[ this.state.blogListIndex ].title }</h1>
			    	</div>
		    	</div>

		        <div className="products" style={{ maxWidth:'900px' }} >
	    			<div style={{ textAlign:'left', padding:'0 30px 10px 30px' }} >
		 		    	{ blogs[ this.state.blogListIndex ].display_date || '' }
			    	</div>
		    	</div>

		        <div className="products" style={{ maxWidth:'900px' }} >
	    			<div style={{ textAlign:'left', padding:'0 30px 10px 30px' }} >
		 		    	{ blogs[ this.state.blogListIndex ].lead || '' }
			    	</div>
		    	</div>

		        <div className="products" style={{ maxWidth:'900px' }} >
	    			<div style={{ textAlign:'left', padding:'0 30px 10px 30px' }} >
		 		    	{ parse( blogs[ this.state.blogListIndex ].copy || '' ) }
			    	</div>
		    	</div>

			</Fragment>
		)
	}
}

export default BlogPage

