import React, { Fragment } from 'react';
import parseHtml from '../parseHtml/parseHtml';

const ThreeColumns = props => {
  return (
  <div className="products-wrapper" style={{ backgroundColor:props.structure.backgroundColor, color:props.structure.color, boxSizing:'border-box', padding:'40px 0 10px 0' }} >
    <div className="products"  >
      <h1 style={{ padding:'0px 30px 0 30px', margin:'0', boxSizing:'border-box' }}>{ props.page.title }</h1>
      <div className="three" style={{ padding:'10px 30px', margin:'0', boxSizing:'border-box' }}>
        { parseHtml( props.structure.column1 || '' ) }
      </div>
      <div className="three" style={{ padding:'10px 30px', margin:'0', boxSizing:'border-box' }}>
        { parseHtml( props.structure.column2 || '' ) }
      </div>
      <div className="three" style={{ padding:'10px 30px', margin:'0', boxSizing:'border-box' }}>
        { parseHtml( props.structure.column3 || '' ) }
      </div>
    </div>
  </div>
  )
}

export default ThreeColumns
