import * as THREE from 'three'
import React, { Fragment, Suspense, useRef, useEffect, useState } from "react"
import { EffectComposer, DepthOfField, Bloom, Noise, Vignette } from '@react-three/postprocessing'
import { Canvas, useThree } from "@react-three/fiber"
import { OrbitControls } from "@react-three/drei"
//import TiltedCubes from '../models/TiltedCubes';
import Icosahedrons from '../models/Icosahedrons';

export default function DiamondsBackground( props ) {

  const [ mesh, setMesh ] = useState( 0 )

  useEffect( () => {
  }, [ mesh ] )

  const basicStyle = { 
    position:'absolute', top:0, left:0, 
    width:'100%', height:'28vh', minHeight:'300px', 
    margin:'0', 
    background:'#37474f' 

  }

  const additionalStyle = props.style || {}

  //const gradient = { background:'linear-gradient( rgba(0,0,0,0.25), rgba(0,0,0,0.125), rgba(0,0,0,0), rgba(255,255,255,0.125), rgba(255,255,255,0.25), rgba(255,255,255,0.5), rgba(255,255,255,0.75), rgba(255,255,255,1) )' }
  //const gradient = { background:'linear-gradient( rgba(0,0,0,0.25), rgba(0,0,0,0.125), rgba(0,0,0,0.06), rgba(0,0,0,0), rgba(255,255,255,0.125), rgba(255,255,255,0.25), rgba(255,255,255,0.5), rgba(255,255,255,0.75), rgba(255,255,255,1) )' }
  const gradient = { background:'linear-gradient( rgba(0,0,0,0.225), rgba(0,0,0,0.15), rgba(0,0,0,0.075), rgba(0,0,0,0), rgba(0,0,0,0), rgba(255,255,255,0.075), rgba(255,255,255,0.15), rgba(255,255,255,0.225) )' }

  const mergedStyle = { ...basicStyle, ...additionalStyle }

  // https://codesandbox.io/s/pqrpl

  return (
    <Fragment>

      <Canvas 
        concurrent 
        style={ mergedStyle } 
        pixelRatio={ window.devicePixelRatio }
        camera={{ zoom:3.8, position: [0, 0, 1.75] }}
      >
        <ambientLight intensity={ 0.7 } />
        <spotLight 
          intensity={ 1.3 } 
          angle={ 0.1 } 
          penumbra={ 1 } 
          position={ [ -5, 25, 20 ] } 
          castshadow
        />
        <spotLight 
          intensity={ 1.3 } 
          angle={ 0.1 } 
          penumbra={ 1 } 
          position={ [ 5, 25, 20 ] } 
          castshadow
        />
        <spotLight 
          intensity={ 1.3 } 
          angle={ 0.1 } 
          penumbra={ 1 } 
          position={ [ 0, 15, 10 ] } 
          castshadow
        />

        <fogExp2 attach="fog" args={[ '#407c81', 0.05 ]} />

        <Suspense fallback={null} >
          <Icosahedrons 
            //colors={ [ '#1b5e20', '#2e7d32', '#1b5e20', '#2e7d32', '#1b5e20' ] } 
            colors={ [ '#455a64', '#607d8b', '#455a64', '#607d8b', '#455a64' ] } 
            roundness={ 0 }
            speed={ 0.00002 }
            horizontal={ false }
            size={ 1.5 }
          />
        </Suspense>

        <OrbitControls 
          //minPolarAngle={ -Math.PI*2 } 
          //maxPolarAngle={ Math.PI*2 } 
          minAzimuthAngle={ 0 }
          maxAzimuthAngle={ 0 }
          enableDamping={ true }
          dampingFactor={ 0.05 } 
          //autoRotate={ false }
          //autoRotateSpeed={ 0.5 }
          enableZoom={ true } 
          minDistance={ 1 }
          maxDistance={ 3 }
          enablePan={ false } 
        />

          {/*
        <EffectComposer>
          <DepthOfField focusDistance={ 0 } focalLength={ 0.01 } threshold={ 0.8 } rings={ 3 } samples={ 3 } maxblur={ 2 } bokehScale={ 4 } height={ 480 } />
          <Bloom kernelSize={ 5 } resolution={ 240 } blurScale={ 1 } luminanceThreshold={0} luminanceSmoothing={0.9} height={300} />
          <Noise opacity={0.02} />
          <Vignette eskil={false} offset={0.1} darkness={1.1} />
        </EffectComposer>

          */}

        <EffectComposer>
          <DepthOfField
            focusDistance={ 0 }
            focalLength={ 0.00002 }
            bokehScale={ 0 }
            height={ 240 }
          />
          {/*
          <Bloom
            luminanceThreshold={ 0 }
            luminanceSmoothing={ 0.9 }
            height={ 300 }
            opacity={ 3 }
          />
          */}
        </EffectComposer>


      </Canvas>

      <div style={{ ...basicStyle, ...gradient }}  />


    </Fragment>
  )
}
