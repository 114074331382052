import React, { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import Store from '../Store/Store';
import Block from '../LongScroll/Block';
import parse from 'html-react-parser';
import styled from 'styled-components'
import Background from '../Fiber/DiamondsBackground';
import ActionButton from '../ActionButton/ActionButton';


const ContainerDiv = styled.div`
  text-align:center; 
  width:100%; 
  max-width:260px;
  margin:0 auto;
  @media only screen and ( min-width: 961px) {
    max-width:100%;
  }
`;

const TabDiv = styled.div`
  display:table; 
  margin:4px auto;
  font-size:0.9rem;
  white-space:normal; padding:2px 0 0 0;
  @media only screen and ( min-width: 961px) {
    display:inline-block; 
    margin:8px;
    font-size:0.8rem; 
  }
`;

const FlexDiv = styled.div`
  display:flex; 
  flex-direction:column;
  align-items:center;
  justify-content:center;
  background:#000;
  color:#78909c;
  padding:10px 0;
  @media only screen and ( min-width: 961px) {
    flex-direction:row;
  }
`;

const Tabs = props => {
  let prevSlug = null
  const lang = Store.get( 'lang' )
  const cookies = Store.get( 'cookies' )

  //console.log( 'HeroTabs' )
  if ( cookies === 'all' ) {
    //ReactGA.pageview( window.location.pathname + window.location.search );
  }

  return(
  <div style={{ 
    position:'relative', width:'100%', 
    padding:'2px', 
    textAlign:'center', 
    color:'black', fontWeight:'700', 
    whiteSpace:'normal', boxSizing:'border-box',  
  }} >
    <ContainerDiv>
      { props.pages.map( ( a, i ) => {
        //console.log( 'a', a )
        let structure = JSON.parse( a.structure )
        if ( prevSlug === a.slug ) { 
          return <Fragment key={ a.id } /> 
        } else {
          prevSlug = a.slug
          return (
            <Link key={ a.id }  to={ '/' + props.section + '/' + a.slug } draggable="false" >
            <TabDiv 
              key={ a.id } 
              style={{ 
                color:props.index === i ? 'black' : '#999', 
                borderBottom:props.index === i ? '4px solid #69f0ae' : '4px solid rgba(0,0,0,0)', 
              }}
            > 
              { parse( ( lang === 'fi' ? structure.titleFi : a.title ) || a.title ) }
            </TabDiv>
            </Link>
          ) 
        }
      } ) }
    </ContainerDiv>
  </div>
  )  
}

const PipsBox = props => (
  <div style={{ padding:'20px', textAlign:'center' }} >
    <div style={{ fontSize:'34px' }} >{ props.figure }</div>
    <div style={{ fontSize:'9px' }} >{ props.title }</div>
  </div>
)

const Pips = props => {
  return(
    <FlexDiv>
      <PipsBox figure="0.0" title="Pip Spreads" />
      <PipsBox figure="1:500" title="Leverage" />
      <PipsBox figure="0.01" title="Micro Lot Trading" />
      <PipsBox figure="1750+" title="Tradable Instruments" />
    </FlexDiv>
  )
}

const BidAskBox = props => (
  <div style={{ padding:'0 10px 0 0' }} >
    <div style={{ fontSize:'12px', fontWeight:'700', color:'#546e7a' }} >{ props.title }</div>
    <div style={{ display:'flex', color:'#00c853', alignItems:'flex-end' }} >
      <div style={{ fontSize:'17px', transform:'translateY(5px)' }} >{ props.figure1 }</div>
      <div style={{ fontSize:'32px', padding:'0 5px' }} >{ props.figure2 }</div>
      <div style={{ fontSize:'17px', transform:'translateY(-5px)' }} >{ props.figure3 }</div>
    </div>
  </div>
)



const CurrencyBox = props => (
  <div style={{ padding:'20px', textAlign:'left' }} >

    <div style={{ display:'flex', alignItems:'flex-end', justifyContent:'left' }} >
      <div style={{ fontSize:'14px', fontWeight:'700' }} >{ props.title }</div>
      <div style={{ fontSize:'12px', fontWeight:'700', color:'#00c853', justifySelf:'end', padding:'0 5px', transform:'translateY(7px)' }} ><span className="material-icons">trending_up</span></div>
      <div style={{ fontSize:'12px', fontWeight:'300' }} >Spread:</div>
      <div style={{ fontSize:'12px', fontWeight:'700', color:'white', paddingLeft:'5px' }} >{ props.spread }</div>
    </div>


    <div style={{ display:'flex', alignItems:'center', justifyContent:'center', padding:'0' }} >
      <BidAskBox title="Bid" figure1={ props.bid1 } figure2={ props.bid2 } figure3={ props.bid3 } />
      <BidAskBox title="Ask" figure1={ props.ask1 } figure2={ props.ask2 } figure3={ props.ask3 } />
    </div>

  </div>
)


//  <div style={{ display:'flex', alignItems:'center', justifyContent:'center', background:'#000', color:'#78909c', padding:'10px 0' }} >
//  </div>

const Currencies = props => (
  <FlexDiv>
    <CurrencyBox title="EUR/USD" bid1="1.21" bid2="64" bid3="8" ask1="1.21" ask2="64" ask3="9" spread="0.1" />
    <CurrencyBox title="AUD/USD" bid1="0.77" bid2="38" bid3="4" ask1="0.77" ask2="38" ask3="4" spread="0.0" />
    <CurrencyBox title="GBP/USD" bid1="1.41" bid2="60" bid3="4" ask1="1.41" ask2="60" ask3="1" spread="0.3" />
    <CurrencyBox title="XAU/USD" bid1="1889" bid2="02" bid3="" ask1="1889" ask2="16" ask3="" spread="0.0" />
  </FlexDiv>
)

//  <div style={{ display:'flex', alignItems:'center', justifyContent:'center', background:'#000', color:'#78909c', padding:'10px 0' }} >
//  </div>

const CallToAction = props => (
  <FlexDiv>
    <ActionButton style={{ background:'#00c853', margin:'10px', color:'black' }} title="START TRADING" />
    <ActionButton style={{ background:'#455a64', margin:'10px' }} title="TRY A FREE DEMO" />
  </FlexDiv>
)

const HeroTabs = props => {
  let allPages = Store.get( 'pages' ) || []
  // get and filter pages for tabs
  let pages = allPages.filter( a => a.section === props.structure.tabsSection )
  pages = pages.filter( a => a.template !== 'hero' )
  pages = pages.filter( a => a.template !== 'hero-tabs' )

  // get tabIndex
  let tabIndex = 0
  let prevSlug = null
  let subContent = []
  if ( props.slug ) {
    pages.forEach( ( a, i ) => {
      if ( a.slug === props.slug  ) {
        if ( prevSlug !== a.slug ) {
          tabIndex = i
        }
        subContent.push( a )
      }
      prevSlug = a.slug
    } )
  }
  console.log( 'pages', pages )
  console.log( 'subContent', subContent )
  // get current page
  let page = {}
  if ( pages && pages.length > 0 && pages[ tabIndex ] && pages[ tabIndex ].hasOwnProperty( 'structure' ) ) {
    page = pages[ tabIndex ]
  }


  // nack from sub-page
  let showBackButton = false
  let mainTitle = page.title
  let linkBackToMain = '/' + props.section + '/' + page.slug

  // get and filter subPages, and replace page  
  if ( props.sub && props.slug && props.structure.subSection && props.structure.subSectionSlug && props.slug === props.structure.subSectionSlug ) {
    let subPages = allPages.filter( a => a.section === props.structure.subSection )
    if ( props.slug ) {
      subPages.forEach( ( a, i ) => {
        if ( a.slug === props.sub ) {
          page = a
          showBackButton = true
        }
      } )
    }
  }

  console.log( 'subContent', subContent )

  useEffect( () => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
  }, [ page ] )

  const lang = Store.get( 'lang' )

  return (
    <Fragment>
      <div id="frontpage" className="front-wrapper" style={{ height:'28vh', minHeight:'300px',  }} >
        {/*
        <img style={{ position:'absolute', top:0, left:0, width:'100%', height:'28vh', minHeight:'300px', objectFit:'cover', objectPosition:'50% 0', opacity:1 }} alt="" src={ props.files[ 0 ] } />
        <Background/>
        */}
        <div style={{ position:'absolute', width:'100%', maxWidth:'1240px', height:'28vh', minHeight:'300px', top:0, left:'50%', transform:'translateX(-50%)', boxSizing:'border-box' }} >
          <div className="front" style={{ 
              userSelect:'none', display:props.structure.showTitle === 'on' ? 'block' : 'none', 
              textAlign:props.structure.titlePosition, 
              color:props.structure.titleColor, 
              fontFamily:'Montserrat', 
              fontSize:'2.5rem', lineHeight:'2.5rem', fontWeight:'700', letterSpacing:'.5px', 
              padding:'20px 0 0 0', textShadow:'0 2px 4px rgba(0,0,0,1)', 
            }}>
              { parse( lang === 'fi' ? props.structure.titleFi : props.structure.titleEn ) }
          </div>
          { props.back && 
          <div 
            onClick={ () => { props.history.goBack() } } 
            style={{ display:'block', cursor:'pointer', position:'absolute', left:'30px', bottom:'30px', height:'30px', color:'white', padding:'4px 8px', borderRadius:'20.5px', fontSize:'1rem', background:'rgba(0,0,0,0.15)', textShadow:'0 1px 2px rgba(0,0,0,0.5)' }} 
          >
            <span className="material-icons" style={{ transform:'translateY(3px)' }} >arrow_back</span>
            <div style={{ display:'inline-block', fontWeight:'bold', transform:'translateY(-3px)', padding:'0 10px' }} >Back</div>
          </div>
          }
        </div>

      </div>

      <Currencies/>
      <CallToAction/>
      <Pips/>

      <div style={{ position:'relative', margin:'5px 0 0 0', textAlign:'center', boxSizing:'border-box' }} >
        <Tabs section={ props.section } pages={ pages } index={ tabIndex } />
      </div>

      {/*
      <Block key={ page.id } page={ page } section={ props.structure.section } />
      */}

      { subContent.length > 1 
        ? subContent.map( ( a, i ) => {
            return (
              <Block key={ a.id + '-' + i } page={ a } section={ props.structure.section } />
            )
          } ) 
        : <Block key={ page.id } page={ page } section={ props.structure.section } />
      }

    </Fragment>
  )
}

export default HeroTabs
