import Request from '../Request/Request';
import Store from '../Store/Store';

const Data = () => {

	const fetch = callBack => {
		Request.get( 'data', 
		result => { 

			let pages = result.data.pages || []
			let pagesObject = {}
			pages.forEach( ( r, i ) => {
				if ( !pagesObject.hasOwnProperty( r.section ) ) {
					pagesObject[ r.section ] = []
				}
				pagesObject[ r.section ].push( r )
			} ) 

			Store.set( 'pages', pages )
			Store.set( 'pagesObject', pagesObject )

			/*

			let blogs = result.data.blogs || []
			blogs.forEach( ( r, i ) => {
				r.productListId = r.productlist_id
			} ) 
			Store.set( 'blogs', blogs )

			let news = result.data.news
			Store.set( 'news', news )

			let employees = result.data.employees
			Store.set( 'employees', employees )

			let companies = result.data.companies
			Store.set( 'companies', companies )

			*/

			let sections = result.data.sections
			console.log( 'sections', sections )
			Store.set( 'sections', sections )

			let data = { 
				pages:pages, 
				pages:pages, 
				//blogs:blogs, 
				//news:news, 
				//employees:employees, 
				//companies:companies, 
				sections:sections 
			}

			//console.log( 'fetch data', data )

			callBack( data )

		} )

	}

	const fetchWithToken = ( token, callBack ) => {
		Request.getWithToken( 'data', token,
		result => { 

			let pages = result.data.pages || []
			let pagesObject = {}
			pages.forEach( ( r, i ) => {
				if ( !pagesObject.hasOwnProperty( r.section ) ) {
					pagesObject[ r.section ] = []
				}
				pagesObject[ r.section ].push( r )
			} ) 

			Store.set( 'pages', pages )
			Store.set( 'pagesObject', pagesObject )

			let sections = result.data.sections
			//console.log( 'sections', sections )
			Store.set( 'sections', sections )

			let data = { 
				pages:pages, 
				sections:sections 
			}

			//console.log( 'fetch data', data )

			callBack( data )

		} )

	}


	return { 
		fetch:fetch, 
		fetchWithToken:fetchWithToken, 
		blogs:Store.get( 'blogs' ) || [], 
		pages:Store.get( 'pages' ) || [], 
		pagesObject:Store.get( 'pagesObject' ) || [], 
		//news:Store.get( 'news' ) || [], 
		//employees:Store.get( 'employees' ) || [], 
		//companies:Store.get( 'companies' ) || [], 
		sections:Store.get( 'sections' ) || [], 
	}


}

export default Data
