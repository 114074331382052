import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'; 
import parseHtml from '../parseHtml/parseHtml';
import styled from 'styled-components'
import Store from '../Store/Store'

const Ahover = styled.div`
  a { text-decoration:none; color:black; display:inline-block; padding-bottom:0; border-bottom:2px solid rgba(0,0,0,0); }
  a:hover { border-bottom:2px solid rgb(32, 190, 225); }
`;

const GenericWithHeader = props => {

  return (
  <div className="products-wrapper" style={{ 
    backgroundColor:props.structure.backgroundColor || '#fff', 
    color:props.structure.color || '#000', 
    padding:'0', 
    boxSizing:'border-box', 
  }} >
    <div className="products" style={{ maxWidth:'900px' }}  >
      <Ahover style={{ padding:'10px 30px', margin:'0', fontSize:'15px', boxSizing:'border-box' }}>
        { parseHtml( props.structure.column || '' ) }
      </Ahover>
    </div>
  </div>
  )
}
export default GenericWithHeader
