import React from 'react';
import { Link } from 'react-router-dom';
import ActionButton from '../ActionButton/ActionButton';

const NewsCard = props => (
	<Link 
		draggable="false" 
		to={{
		  pathname:'/news/' + props.slug + '/',
		  state: {
		    backFromPageTo:props.backFromPageTo
		  }
		}} 
		className="blogs-list-block"
	>
		<table className="card-hover" style={{ border:'1px solid #ddd' }}  >
			<tbody>
				<tr><td colSpan="2" style={{ width:'100%', padding:'0 10px 0 10px', margin:'0', boxSizing:'border-box' }} >
					<img 
						src={ props.row.news_img || props.row.company_img } 
						draggable="false" 
						alt="" 
						style={{ 
							objectFit:'cover', margin:'0', 
							width:'100%', height:'100%', 
							maxWidth:'160px', maxHeight:'160px', 
							boxSizing:'border-box',  
						}} 
					/>
				</td></tr>
				<tr><td colSpan="2" style={{ padding:'10px 10px 0 10px', verticalAlign:'top', boxSizing:'border-box' }} >
			    	<div style={{ 
			    		padding:'0', margin:'0', fontSize:'0.8rem', lineHeight:'1.25rem', textAlign:'center', 
			    		height:'80px', overflow:'hidden', boxSizing:'border-box' }} >
			    		{ props.row.title }
			    	</div>
				</td></tr>
				<tr><td colSpan="2" style={{ padding:'0 10px 8px 10px', verticalAlign:'top', boxSizing:'border-box' }} >
			    	<div style={{ padding:'0', margin:'0', textAlign:'center', height:'52px', overflow:'hidden', boxSizing:'border-box' }} >
						<ActionButton title="READ MORE" style={{ display:'inline-block' }} />
			    	</div>
				</td></tr>


			</tbody>
		</table>
	</Link>
)

export default NewsCard
