import React, { Fragment, useState } from 'react';
import Store from '../Store/Store';
import { Link, Router, useLocation } from 'react-router-dom'; 
import parseHtml from '../parseHtml/parseHtml';
import styled from 'styled-components'
import icons from '../icons/icons';
import Icon from '../Icon/Icon';
import parse from 'html-react-parser';
import EditableColumn from '../EditableColumn/EditableColumn'

import ActionButton from '../ActionButton/ActionButton';
import Logout from '../Logout/Logout'

//import Register from '../Register/Register';

//import bgImageWhite from '../img/logo_white.png';
//let bgUrl = `${ bgImageWhite }`

const FooterDiv = styled.div`
  p { font-size: 0.8rem; line-height: 1.4rem; margin:0 0; font-weight:300; }
  a { text-decoration:none; font-weight:700; color:#fff; display:inline-block; padding-bottom:0; }
  a:hover { color: #ca9119; }
  @media only screen and ( min-width: 801px) {
  }
`;

const FooterIcon = props => (
  <div style={{ 
    display:'inline-block', 
    background:'white', 
    fill:'#263238', 
    width:'32px', 
    height:'32px', 
    padding:'0px',
    borderRadius:'16px', 
    margin:'10px' 
  }} >
  <a href={ props.to } target="_blank" > 
    <Icon icon={ props.icon } style={{ padding:'6px', width:'20px', height:'20px' }} />
  </a>
  </div>
)

const SignIn = props => {
  return (
    <div style={{ 
      display:'inline-block', 
      padding:0,
      margin:'0 10px' 
    }} >
      { props.user 
      ? <div style={{ display:'inline-block', transform:'translateY(-8px)' }} ><Logout { ...props } /></div>
      : <Link to="/login/">
          <ActionButton style={{ background: process.env.REACT_APP_ACCENT_COLOR || '#80d8ff', color:'black' }} title="SIGN IN" />
        </Link>
      }
    </div>
  )
}

const FooterBlock = props => {
  let pageStructure = JSON.parse( props.page.structure || '{}' )
  const [ structure, setStructure ] = useState( pageStructure )
  //let copyHtml = parseHtml( props.lang === 'fi' ? s.columnFi : s.column || '' )
  return ( 
    <div key={ props.page.id } className='footer-item-group' >
      <EditableColumn 
        tagName="h5"
        columnName="title"
        style={{ marginTop:0, textTransform:'uppercase' }}
        showCommands={ false }
        showHovers={ false }
        page={ props.page }
        structure={ structure }
        setStructure={ setStructure }
        {...props}
      />


      <EditableColumn 
        tagName="div"
        style={{ color:'white' }}
        columnName="column"
        className="footer-div"
        showCommands={ true }
        showHovers={ true }
        page={ props.page }
        structure={ structure }
        setStructure={ setStructure }
        {...props}
      />
    </div>
  )
}

const Footer = props => {
  let pagesObject = Store.get( 'pagesObject' ) || {}
  let footerArray = pagesObject.footer

  const location = useLocation()
  if ( location.pathname === '/login/' ) return null

  return (
  <div className="footer-wrapper" style={{ width:'100%', overflowX:'hidden' }} >


    <div className="footer" style={{ marginTop:'30px', maxWidth:'900px' }} >

    {/*
    <Register structure={{ color:'white', backgroundColor:'rgba(0,0,0,0)' }} />
    */}

      <div style={{ display:'inline-block' }} >
        <div className="footer-flex-box" >
          { footerArray.map( ( a, i ) => (
            <FooterBlock key={ a.id } { ...props } page={ a } />
          ) ) }
        </div>

        <div style={{ textAlign:'left', margin:'50px 0 0 20px' }} >
          <div style={{ display:'inline-block', fontSize:'0.8rem', verticalAlign:'top', padding:'15px 20px 15px 10px' }} >
            { process.env.REACT_APP_COPYRIGHT }
          </div>
          <div style={{ display:'inline-block', verticalAlign:'top', padding:'10px 20px 15px 10px' }} >
            <SignIn { ...props } />
            {/*
            <FooterIcon icon="linkedin" to="https://www.linkedin.com/" />
            <FooterIcon icon="facebook" to="https://www.facebook.com//" />
            <FooterIcon icon="twitter" to="https://twitter.com/" />
            <FooterIcon icon="instagram" to="https://www.instagram.com/" />
            */}
          </div>
        </div>

      </div>

    </div>

  </div>

  )
}

export default Footer 
