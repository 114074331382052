import React, { Fragment } from 'react';
import parse from 'html-react-parser';
import getArticleAndImage from '../News/getArticleAndImage';
import NewsBannerBySlug from '../News/NewsBannerBySlug';

const NewsBannerOneColumn = props => {
  const { newsArticle, imageUrl } = getArticleAndImage( props.structure.columnSlug )
  //console.log( 'props.structure', imageUrl, props.structure )
  return (
  <div className="products-wrapper" style={{ 
  	backgroundColor:'white' || props.structure.backgroundColor, 
  	color:props.structure.color, 
  	padding:'30px 0 0 0', 
  	boxSizing:'border-box', 

    width:'100%', height:'560px', position:'relative', 
    backgroundSize:'cover', backgroundPosition:'50% 0', 
    backgroundImage:'linear-gradient(rgba(21, 45, 85, 0.05), rgba(21, 45, 85, 0.15), rgba(21, 45, 85, 0.2), rgba(21, 45, 85, 0.05)), url('+(newsArticle.news_img || newsArticle.company_img)+')' 

  }} >
    <div className="products"  >
      <div className="two" style={{ width:'100%', padding:'0 30px', margin:'0', boxSizing:'border-box' }}>
        <NewsBannerBySlug structure={ props.structure } slug={ props.structure.columnSlug } />
      </div>
    </div>
  </div>
  )
}

export default NewsBannerOneColumn
