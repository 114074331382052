import React from 'react';
import { Link } from 'react-router-dom';
import parseHtml from '../parseHtml/parseHtml';
import getArticleAndImage from '../News/getArticleAndImage';
import ActionButton from '../ActionButton/ActionButton';

const NewsBannerBySlug = props => {

	let backTo = 'back'
	const { newsArticle, imageUrl } = getArticleAndImage( props.slug )
    let leadHtml = parseHtml( newsArticle.lead || '' )

	return(
		<Link 
			draggable="false" 
			to={{
			  pathname:'/news/' + props.slug + '/',
			  state: {
			    backFromPageTo:backTo
			  }
			}} 
			className="blogs-list-block"
			style={{ width:'100%' }}
		>
			{/*
			<div style={{ 
				width:'100%', height:'400px', position:'relative', 
				//backgroundSize:'cover', backgroundPosition:'50% 0', 
				//backgroundImage:'linear-gradient(rgba(21, 45, 85, 0.05), rgba(21, 45, 85, 0.15), rgba(21, 45, 85, 0.2), rgba(21, 45, 85, 0.05)), url('+(newsArticle.news_img || newsArticle.company_img)+')' 
			}} >
			*/}
				<div style={{ 
					display:'block', opacity:'1', position:'absolute', left:'50%', top:'50%', color:'white', 
					boxSizing:'border-box',							
					transform:'translate(-50%,-50%)',
					textAlign:'center',
					margin:0, padding:0
				}}>
					<span style={{ 
					    fontFamily:'futuraheavy', textTransform:'uppercase', letterSpacing:'2px', 
						textShadow:'0 2px 4px rgba(0,0,0,0.5)', 
						lineHeight:'3.5rem', 
						fontSize:'3rem', 
						fontWeight:'700' 
					}} >
					{ props.structure.titleFi }
					</span>
					<br/>
					<br/>
					<span style={{ 
						fontFamily:'Montserrat', 
						textShadow:'0 1px 2px rgba(0,0,0,0.5)', 
						fontSize:'2rem', 
						fontWeight:'500', 
					}} >
						{ newsArticle.title  }
					</span>
					<br/>
					<div style={{ display:'inline-block', position:'relative', marginTop:'30px' }} >
					<ActionButton title="LUE LISÄÄ" style={{ width:'120px' }} />
					</div>
				</div>
			{/*
			</div>
			*/}
		</Link>
	)
}

export default NewsBannerBySlug
