import React from 'react';
import { Link } from 'react-router-dom';
import parseHtml from '../parseHtml/parseHtml';
import getArticleAndImage from '../News/getArticleAndImage';

const NewsCardBySlug = props => {

	let backTo = 'back'
	const { newsArticle, imageUrl } = getArticleAndImage( props.slug )
    let leadHtml = parseHtml( newsArticle.lead || '' )

	return(
		<Link 
			draggable="false" 
			to={{
			  pathname:'/news/' + props.slug + '/',
			  state: {
			    backFromPageTo:backTo
			  }
			}} 
			className="blogs-list-block"
			style={{ width:'100%' }}
		>
			<table className="card-hover" >
				<tbody>
					<tr><td colSpan="2" style={{ width:'100%', height:'270px', padding:'0', margin:'0', boxSizing:'border-box' }} >
						<img 
							src={ newsArticle.news_img || newsArticle.company_img } 
							draggable="false" 
							alt="" 
							style={{ objectFit:'cover', width:'100%', height:'270px', boxSizing:'border-box',  }} 
						/>
					</td></tr>
					<tr><td colSpan="2" style={{ padding:'4px 10px 4px 10px', verticalAlign:'top', boxSizing:'border-box' }} >
				    	<div 
				    		style={{ 
				    			padding:'0', margin:'0', 
				    			fontSize:'18px', letterSpacing:'2.5px', 
				    			lineHeight:'20px', height:'40px', overflow:'hidden', boxSizing:'border-box' }} 
			    		>
					    	{ newsArticle.title }
				    	</div>
					</td></tr>
					<tr><td colSpan="2" style={{ padding:'4px 10px 10px 10px', verticalAlign:'top', boxSizing:'border-box' }} >
				    	<div style={{ padding:'0', margin:'0', fontSize:'14px', lineHeight:'18px', height:'54px', overflow:'hidden', boxSizing:'border-box' }} >
					    	{ leadHtml }
				    	</div>
					</td></tr>
				</tbody>
			</table>
		</Link>
	)
}

export default NewsCardBySlug
