import React from 'react';
import Background from '../Fiber/Background';
 
const HeroFront = props => (
  <div id="frontpage" className="front-wrapper" style={{ height:'100vh' }} >
    {/*
    <img style={{ position:'absolute', top:0, left:0, width:'100%', height:'28vh', minHeight:'300px', 
                  objectFit:'cover', objectPosition:'50% 0', opacity:1 }} 
         src={ props.files[ 0 ] } 
    />
    */}
    <Background style={{ height:'100vh' }} />
    <div style={{ position:'absolute', width:'100%', maxWidth:'1240px', height:'100vh', minHeight:'300px', top:0, left:'50%', transform:'translateX(-50%)', boxSizing:'border-box' }} >
      <div className="front" style={{ 
          userSelect:'none', display:props.structure.showTitle === 'on' ? 'block' : 'none', 
          textAlign:props.structure.titlePosition, 
          fontFamily:'Montserrat', 
          fontSize:'2.5rem', lineHeight:'2.5rem' , fontWeight:'700', letterSpacing:'.5px', 
          padding:'30px 0 0 0', 
          textShadow:'0 2px 4px rgba(0,0,0,1)', 
          color:props.structure.titleColor, 
        }}>
          { props.structure.titleText + 'x' }
      </div>
      { props.back && 
      <div 
        onClick={ () => { props.history.goBack() } } 
        style={{ display:'block', cursor:'pointer', position:'absolute', left:'30px', bottom:'30px', height:'30px', color:'white', padding:'4px 8px', borderRadius:'20.5px', fontSize:'1rem', background:'rgba(0,0,0,0.15)', textShadow:'0 1px 2px rgba(0,0,0,0.5)' }} 
      >
        <span className="material-icons" style={{ transform:'translateY(3px)' }} >arrow_back</span>
        <div style={{ display:'inline-block', fontWeight:'bold', transform:'translateY(-3px)', padding:'0 10px' }} >Palaa</div>
      </div>
      }
    </div>

  </div>
)

export default HeroFront
